export function validatePhoneNumber(phone: string): boolean {
  return /^(\+|\d)[0-9]{7,16}$/.test(phone);
}

export function validateEmail(email: string): boolean {
  // Uh yeah...
  return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
    email,
  );
}

export function validateUsername(username: string): boolean {
  // Usernames must contain be 2+ ASCII characters
  return /^[a-zA-Z0-9]{2,}$/.test(username);
}

export function validateDisplayName(name: string): boolean {
  // Names must be 2+ "words" containing at least two letters each, optionally interleaved by dashes.
  return /^\S+(?: \S+)+$/.test(name);
}

export function validatePassword(password: string): boolean {
  // Passwords must be a 8-64 letter string containing at least on lowercase letter, one uppercase letter and a number
  if (password.length < 8 || password.length > 64) {
    return false;
  }

  return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(password);
}

export function validateURL(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}
