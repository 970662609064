import { styled } from 'styled-components';
import React, { FC, ReactNode } from 'react';
import { FieldInputProps, useField } from 'formik';
import { Styling } from '@idk-web/core-ui';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const ErrorMessage = styled.div<{ isStatic: boolean }>`
  ${Styling.typography('body')}
  font-size: 0.8rem;
  position: ${({ isStatic }) => (isStatic ? 'absolute' : 'relative')};
  color: #e33939;
  margin: ${Styling.spacing(0.5, 0)};
  text-overflow: ellipsis;
`;

export type FormFieldRenderProps = {
  valid?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & FieldInputProps<any>;

export type FormFieldProps = {
  name: string;
  /**
   * The field will always take the same amount of space no matter whether it's valid or not if it's static.
   * @default true
   */
  isStatic?: boolean;
  render(props: FormFieldRenderProps): ReactNode;
};

export const FormField: FC<FormFieldProps> = ({
  name,
  isStatic = true,
  render,
}) => {
  const [field, meta] = useField(name);

  return (
    <Container>
      {render({
        ...field,
        valid: meta.touched ? !meta.error : undefined,
      })}
      {meta.touched && meta.error && (
        <ErrorMessage isStatic={isStatic}>{meta.error}</ErrorMessage>
      )}
    </Container>
  );
};
